import Time from "./Time";

const clickArea = document.querySelector("[data-click-area]");

//  <div class="c-note">
//    NOT TO BRAG BUT WE MADE THIS IN 2 HOURS - FULL SITE COMING SOON
//  </div>;

const delay = 5000;

const getRandomItem = (length) => Math.floor(Math.random() * length);

const getRandomArbitrary = (min, max) => {
  return Math.random() * (max - min) + min;
};

const stickyNoteTexts = [
  "FIND US ON SOCIAL MEDIA AS @HOWTONULL",
  "WITH ♥ FROM LAGOS, NIGERIA.",
  "#MAKETHEWEB <br> FUNAGAIN",
  `<svg width="19" height="29" viewBox="0 0 19 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.825806 10.784H5.56181V5.984H0.825806V10.784ZM0.825806 23.2H5.56181V18.4H0.825806V23.2Z" fill="black"/>
    <path d="M14.3486 28.8C16.2686 25.984 18.7646 20.768 18.7646 14.4C18.7646 8 16.2686 2.784 14.3486 0H10.3166C12.4606 3.52 14.2206 8.544 14.2206 14.4C14.2206 20.288 12.4606 25.312 10.3166 28.8H14.3486Z" fill="black"/>
  </svg>
`,
  "WE MAKE INTERNET BANGERS",
  "WE LOVE CREATING SEXY WEBSITES",
  "New site coming soon",
  "AVAILABLE FOR NEW PROJECTS FROM July 2023",
  "WE DO <br> E-COMMERCE, WEBFLOW & CREATIVE WEB DEVELOPMENT",
  "WE DO DIGITAL DESIGN, BRANDING & CREATIVE DIRECTION",
  "We think pineapple doesn’t belong on pizza :)",
  "sticker:null",
  "Cannot read property 'studio' <br> of null",
];

export const stickyNoteColors = [
  "FFFF00",
  "FF0000",
  "FF00FF",
  "00FF00",
  "00FFFF",
];

let duplicateTexts = stickyNoteTexts.slice();
let duplicateColors = stickyNoteColors.slice();

const createStickyNote = () => {
  const text = duplicateTexts[getRandomItem(duplicateTexts.length)];
  const color = duplicateColors[getRandomItem(duplicateColors.length)];

  duplicateTexts = duplicateTexts.filter((item) => item !== text);
  duplicateColors = duplicateColors.filter((item) => item !== color);

  if (duplicateTexts.length === 0) {
    duplicateTexts = stickyNoteTexts.slice();
  }

  if (duplicateColors.length === 0) {
    duplicateColors = stickyNoteColors.slice();
  }

  const stickyNote = document.createElement("div");

  stickyNote.classList.add("c-note");

  if (text.includes("svg")) {
    stickyNote.classList.add("c-note--centered");
  }

  stickyNote.innerHTML = `
    <div class="c-note__inner" style="background-color: #${color}">
      ${text}
    </div>
  `;

  return stickyNote;
};

const showStickyNote = (x, y) => {
  const stickyNote = createStickyNote();

  const centerX = x - 192 / 2;

  stickyNote.style.transform = `translate(${centerX}px, ${y}px) rotate(${getRandomArbitrary(
    -20,
    20
  )}deg)`;

  clickArea.appendChild(stickyNote);

  setTimeout(() => {
    stickyNote.style.opacity = 0;
  }, delay);

  setTimeout(() => {
    clickArea.removeChild(stickyNote);
  }, delay + 1000);
};

clickArea.addEventListener("click", (e) => {
  if (!e.target.classList.contains("disabled")) {
    showStickyNote(e.clientX, e.clientY);
  }
});

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

window.addEventListener("resize", () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

new Time();
